
















































































































































































































































































































































































































































































































































import DatePickerLang from "@/helpers/DatePickerLang";
import {
  NewContract,
  PaymentMethod,
  Product,
  CommonData,
  ModalAddType,
  ContractStatus
} from "@/models";
import { Component, Prop, Vue } from "vue-property-decorator";
//@ts-ignore
import { VueAutosuggest } from "vue-autosuggest";
import ProductList from "@/components/ProductList.vue";
import CommonService from "@/services/CommonService";
import ContractService from "@/services/ContractService";
import moment from "moment";

@Component({ components: { VueAutosuggest, ProductList } })
export default class FormCreateContract extends Vue {
  @Prop({ default: "modal-create-contract" }) id!: string;
  @Prop() contractDetail?: NewContract;
  @Prop({ default: false }) showDocBtn!: boolean;
  private newContract: NewContract = new NewContract();
  private datePickProp: DatePickerLang = new DatePickerLang();
  private eModalAddType = ModalAddType;
  //select
  private eAutocomplate: any = EAutocomplate;
  private eCombobox: any = ECombobox;
  private company: CommonData[] = [];
  private paymentMethod: string[] = [];
  private origin: CommonData[] = [];
  private supplier: CommonData[] = [];
  private port: CommonData[] = [];

  private originQuery: string = "";
  private supplierQuery: string = "";
  private portQuery: string = "";

  private originSuggestions: any[] = [];
  private supplierSuggestions: any[] = [];
  private portSuggestions: any[] = [];

  private originString: string = "";
  private supplierString: string = "";
  private portString: string = "";

  private ableClose: boolean = false;
  private ignoreConfirmLeave: boolean = false;

  closeFormCreate() {
    this.$bvModal.hide(this.id);
  }

  handlePreventEvent(event: any) {
    this.newContract = new NewContract();
    this.originQuery = "";
    this.supplierQuery = "";
    this.portQuery = "";
    //TODO
    // if (
    //   !this.ableClose &&
    //   !this.$lodash.isEqual(this.newContract, new NewContract()) &&
    //   !this.ignoreConfirmLeave
    // ) {
    //   event.preventDefault();
    //   this.$swal({
    //     title: "Bạn sẽ thoát và không lưu?",
    //     showCancelButton: true,
    //     showConfirmButton: true,
    //     confirmButtonText: `Thoát`,
    //     cancelButtonText: `Tiếp tục`
    //   }).then(result => {
    //     if (result.isConfirmed) {
    //       this.ableClose = true;
    //       setTimeout(() => {
    //         this.ableClose = false;
    //       }, 100);
    //       this.$bvModal.hide("modal-create-contract");
    //     }
    //   });
    // }
  }

  modalShown() {
    this.getListCompany();
    this.getListSupplier();
    this.getListCountry();
    this.getListPort();

    this.$bus.$on("addSupplier", () => this.getListSupplier());
    this.$bus.$on("addCountry", () => this.getListCountry());
    this.$bus.$on("addPort", () => this.getListPort());

    this.paymentMethod = ["TT", "DP", "LC"];

    if (this.contractDetail && this.contractDetail.id) {
      const timeSigned = this.contractDetail.timeSigned as any;
      this.contractDetail.licenseVM = this.contractDetail.license === "ONLINE";
      this.newContract = this.$lodash.cloneDeep(this.contractDetail);
      this.newContract.timeSigned = this.$moment(
        timeSigned,
        "DD-MM-YYYY HH:mm:ss"
      ).toDate();
      this.originQuery = this.newContract.origin;
      this.supplierQuery = this.getQuery(
        this.newContract.supplier,
        this.newContract.supplierCode
      );
      this.portQuery = this.getQuery(
        this.newContract.portName,
        this.newContract.port
      );
    }
  }

  getQuery(str1: string, str2: string) {
    if (str1 && str2) {
      return `${str1} (${str2})`;
    }
    return "";
  }

  destroyed() {
    this.$bus.$off("addSupplier");
    this.$bus.$off("addCountry");
    this.$bus.$off("addPort");
  }

  /**
   * call API get list company
   */
  async getListCompany() {
    return CommonService.getAllCompany()
      .then(res => {
        if (res.status === 200) {
          this.company = res.data.map((item: any) => new CommonData(item));
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * call API get list country
   */
  async getListCountry() {
    return CommonService.getAllCountry()
      .then(res => {
        if (res.status === 200) {
          let arr: string[] = [];
          this.origin = res.data.map((item: any) => {
            arr.push(`${item.name} (${item.code})`);
            return new CommonData(item);
          });
          this.originString = arr.join(" ");
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * call API get list port
   */
  async getListPort() {
    return CommonService.getAllPort()
      .then(res => {
        if (res.status === 200) {
          let arr: string[] = [];
          this.port = res.data.map((item: any) => {
            arr.push(`${item.name} (${item.code})`);
            return new CommonData(item);
          });
          this.portString = arr.join(" ");
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * call API get list supplier
   */
  async getListSupplier() {
    return CommonService.getAllSupplier()
      .then(res => {
        if (res.status === 200) {
          let arr: string[] = [];
          this.supplier = res.data.map((item: any) => {
            arr.push(`${item.name} (${item.code})`);
            return new CommonData(item);
          });
          this.supplierString = arr.join(" ");
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * input change for autocomplage
   */
  fetchResults(from: EAutocomplate) {
    const filter = this.filterResults(from);
    switch (from) {
      case EAutocomplate.ORIGIN:
        this.originSuggestions = [];
        filter.length && this.originSuggestions.push({ data: filter });
        break;
      case EAutocomplate.SUPPILER:
        this.supplierSuggestions = [];
        filter.length && this.supplierSuggestions.push({ data: filter });
        break;
      case EAutocomplate.PORT:
        this.portSuggestions = [];
        filter.length && this.portSuggestions.push({ data: filter });
        break;
    }
  }

  /**
   * Filter for autocomplete
   */
  filterResults(from: EAutocomplate) {
    switch (from) {
      case EAutocomplate.ORIGIN:
        return this.origin
          .filter((item: any) => {
            if (
              [item["code"], item["name"]]
                .join("")
                .toLowerCase()
                .indexOf(this.originQuery.toLowerCase()) > -1
            ) {
              return `${item["name"]} (${item["code"]})`;
            }
          })
          .sort();
      case EAutocomplate.SUPPILER:
        return this.supplier
          .filter((item: any) => {
            if (
              [item["code"], item["name"]]
                .join("")
                .toLowerCase()
                .indexOf(this.supplierQuery.toLowerCase()) > -1
            ) {
              return `${item["name"]} (${item["code"]})`;
            }
          })
          .sort();
      case EAutocomplate.PORT:
        return this.port
          .filter((item: any) => {
            if (
              [item["code"], item["name"]]
                .join("")
                .toLowerCase()
                .indexOf(this.portQuery.toLowerCase()) > -1
            ) {
              return `${item["name"]} (${item["code"]})`;
            }
          })
          .sort();
      default:
        return [];
    }
  }

  renderSuggestionTooltip(suggestion: any) {
    const h = this.$createElement;

    const messageVNode = h("div", {}, [
      h(
        "div",
        {
          attrs: { id: [suggestion.item.id, suggestion.item.name].join("") }
        },
        suggestion.item.name
      ),
      h(
        "b-tooltip",
        {
          attrs: {
            target: [suggestion.item.id, suggestion.item.name].join(""),
            triggers: "hover",
            placement: "leftbottom"
          }
        },
        [
          h("div", { class: ["text-start"] }, [
            h("div", {}, `SĐT: ${suggestion.item.phone}`),
            h("div", {}, `Email: ${suggestion.item.email}`),
            h("div", {}, `TK Ngân hàng: ${suggestion.item.bankAccount}`),
            h("div", {}, `ĐC Ngân hàng: ${suggestion.item.bankAddress}`),
            h("div", {}, `IBAN: ${suggestion.item.iban}`),
            h(
              "div",
              { class: ["text-line-clamp-2"] },
              `Ghi chú: ${suggestion.item.notice}`
            )
          ])
        ]
      )
    ]);
    // return messageVNode;
    return `${suggestion.item.name} (${suggestion.item.code})`;
  }

  renderSuggestion(suggestion: any) {
    return `${suggestion.item.name} (${suggestion.item.code})`;
  }

  getSuggestionValue(suggestion: any, from: EAutocomplate) {
    switch (from) {
      case EAutocomplate.ORIGIN:
        this.newContract.origin = suggestion.item.code;
        break;
      case EAutocomplate.SUPPILER:
        this.newContract.supplier = suggestion.item.name;
        this.newContract.supplierCode = suggestion.item.code;
        break;
      case EAutocomplate.PORT:
        this.newContract.portName = suggestion.item.name;
        this.newContract.port = suggestion.item.code;
        break;
    }
    return `${suggestion.item.name} (${suggestion.item.code})`;
  }

  /**
   * Hanlge cbx for compay andy payment
   */
  handleSelectBoxChange(index: number, cb: ECombobox) {
    switch (cb) {
      case ECombobox.COMPANY:
        this.newContract.company = this.company[index].code;
        this.newContract.color = this.company[index].color;
        break;
      case ECombobox.PAYMENT:
        this.newContract.paymentMethod = this.paymentMethod[index];
        break;
    }
  }

  getPaymentMethodName(e: PaymentMethod) {
    switch (e) {
      case PaymentMethod.DP:
        return "DP";
      case PaymentMethod.LC:
        return "LC";
      case PaymentMethod.TT:
        return "TT";
      default:
        return "";
    }
  }

  triggerInputChange(from: EAutocomplate) {
    switch (from) {
      case EAutocomplate.ORIGIN:
        (this.$refs.origin as any).reset();
        (this.$refs.origin as any).syncValue(this.newContract.origin);
        break;
      case EAutocomplate.SUPPILER:
        (this.$refs.supplier as any).reset();
        (this.$refs.supplier as any).syncValue(this.newContract.supplierCode);
        break;
      case EAutocomplate.PORT:
        (this.$refs.port as any).reset();
        (this.$refs.port as any).syncValue(this.newContract.port);
        break;
    }
  }

  productAddRow() {
    // this.newContract.contractProductList.push(new Product());
  }

  rematchTotal(listProduct: Product[]) {
    let totalValue = 0;
    // let totalPrice = 0;
    listProduct.forEach(prod => {
      totalValue = totalValue + prod.value * prod.weight;
      // totalPrice = totalPrice + prod.price * prod.weight;
    });
    this.newContract.totalValueTemp = Number(totalValue.toFixed(3));
    // this.newContract.totalPriceTemp = Number(totalPrice.toFixed(3));
  }

  openModalDocument() {
    this.$emit("openModalDocument");
  }

  submitNewcontract() {
    if (!this.contractDetail) {
      //fix data
      this.newContract.status = ContractStatus.NEW;
      this.newContract.creator = this.$store.state.user.username;
    }

    //tranfer data
    this.newContract.contractProductList = (this.$refs
      .product as any).contractProductClone;

    const toString = (str: Date | null) => {
      const mm = this.$moment(str, "DD-MM-YYYY HH:mm:ss");
      if (mm.isValid()) {
        return mm.format("DD-MM-YYYY HH:mm:ss");
      }
      return "";
    };

    this.newContract.billList.forEach(bill => {
      bill.billDate = toString(bill.billDate);
      bill.eta = toString(bill.eta);
      bill.etd = toString(bill.etd);
      bill.etf = toString(bill.etf);
    });

    this.newContract.license = this.newContract.licenseVM
      ? "ONLINE"
      : "OFFLINE";

    //covert to data
    const data: any = Object.assign({}, this.newContract);
    data.paymentMethod = data.paymentMethod ? data.paymentMethod : "NONE";
    const timeCreate = moment(this.newContract.timeSigned);
    if (this.newContract.timeSigned && timeCreate.isValid()) {
      data.timeSigned = timeCreate.format("DD-MM-YYYY HH:mm:ss");
    } else {
      data.timeSigned = "";
    }
    data.billList = [];

    ContractService.createContractBasic(data).then(res => {
      if (res.status === 200) {
        this.$emit("submit", this.newContract);

        //emit to list contract new
        this.$bus.$emit("createContract");

        //confirm leave at list contract new
        this.ignoreConfirmLeave = true;
        this.$bvModal.hide(this.id);

        //case create contact
        if (!this.contractDetail) {
          this.$swal({
            title: "Tạo mới thành công.\n Bạn có muốn thêm file luôn không?",
            icon: "success",
            showCancelButton: true,
            cancelButtonText: "Thoát",
            timer: 8000,
            timerProgressBar: true
          }).then(r => {
            if (r.isConfirmed) {
              this.$emit("saveContractDone", res.data);
            }
          });

          //case update contract
        } else {
          this.$swal({
            title: "Sửa thành công",
            icon: "success",
            timer: 2000,
            toast: true,
            showConfirmButton: false,
            position: "bottom-end"
          });
        }
      }
    });
  }

  formatTotal(sum: number) {
    return new Intl.NumberFormat("currency").format(sum);
  }
}
export enum EAutocomplate {
  ORIGIN,
  SUPPILER,
  PORT
}
export enum ECombobox {
  COMPANY,
  PAYMENT
}
