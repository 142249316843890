




































































































































































































import { Product, IdName, ModalAddType } from "@/models";
import { Component, Prop, Vue } from "vue-property-decorator";
//@ts-ignore
import { VueAutosuggest } from "vue-autosuggest";
import CurrencyInput from "@/components/CurrencyInput.vue";
import CommonService from "@/services/CommonService";

@Component({ components: { VueAutosuggest, CurrencyInput } })
export default class ProductList extends Vue {
  @Prop({ default: "h--55" }) private heightClass!: string;
  @Prop({ default: [] }) private listProduct!: Product[];
  private listProductApi: IdName[] = [];
  private contractProductClone: Product[] = [];
  private totalValue: string = "0";
  private totalPrice: string = "0";
  private eTotalChangeType: any = TotalChangeType;
  private currencyOption: any = {
    locale: "en-US",
    currency: "USD",
    currencyDisplay: "hidden",
    precision: 3,
    valueRange: {
      min: 0
    },
    hideGroupingSeparatorOnFocus: false
  };

  private productSuggestions: any[] = [];
  private eModalAddType = ModalAddType;

  created() {
    this.getListProduct();
    this.watchListProduct();

    this.$bus.$on("addProuct", () => this.getListProduct());
  }

  destroyed() {
    this.$bus.$off("addProuct");
  }

  watchListProduct() {
    if (this.listProduct && this.listProduct.length) {
      this.contractProductClone = this.listProduct;
    }
  }

  /**
   * call API get list supplier
   */
  async getListProduct() {
    return CommonService.getAllProuct()
      .then(res => {
        if (res.status === 200) {
          this.listProductApi = res.data.map(
            (item: any) => new IdName({ id: item.id, name: item.name })
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  renderSuggestion(suggestion: any) {
    return suggestion.item.name;
  }

  getSuggestionValue(suggestion: any, index: number) {
    this.contractProductClone[index].productName = suggestion.item.name;
    return suggestion.item.name;
  }

  fetchResults(index: number) {
    const filter = this.filterResults(index);
    this.productSuggestions = [];
    filter.length && this.productSuggestions.push({ data: filter });
  }

  filterResults(index: number) {
    return this.listProductApi
      .filter((item: any) => {
        if (
          item["name"]
            .toLowerCase()
            .indexOf(
              this.contractProductClone[index].productName.toLowerCase()
            ) > -1
        ) {
          return item["name"];
        }
      })
      .sort();
  }

  triggerInputChange(index: number) {
    (this.$refs[`product-${index}`] as any)[0].reset();
    (this.$refs[`product-${index}`] as any)[0].syncValue(
      this.contractProductClone[index].productName
    );
    this.$emit("submitContainer");
  }

  onChangePack() {
    this.$emit("submitContainer");
  }

  onChangeTotal() {
    this.$emit("rematchTotal", this.contractProductClone);
  }

  async addNewRow() {
    const checkValid = await this.validateAllProduct();
    if (!checkValid) return;
    this.contractProductClone.push(new Product());
    this.$emit("addRow");
  }

  deleteRow(i: number) {
    this.contractProductClone = this.contractProductClone.filter(
      (item: Product, index: number) => index !== i
    );
    this.onChangeTotal();
  }

  async validateAllProduct() {
    const list: any = this.$refs.productValidate;
    if (!list) return true;
    const valids: boolean[] = [];
    await Promise.all(
      list.map(async (productRow: any) => {
        await productRow?.validate();
        const valid = productRow?.flags.valid;
        valids.push(valid);
      })
    );
    return valids.every(item => item);
  }
}

enum TotalChangeType {
  BOTH,
  VALUE,
  PRICE
}
