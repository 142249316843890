var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pt-3 px-1 border border-secondary rounded"},[_vm._m(0),_c('div',{staticClass:"overflow-auto",class:_vm.heightClass},[_vm._l((_vm.contractProductClone),function(product,index){return _c('div',{key:index},[_c('ValidationObserver',{ref:"productValidate",refInFor:true,staticClass:"body row mx-0 mt-3 transition",attrs:{"id":("" + index)}},[_c('span',{staticClass:"col-4"},[_c('div',{staticClass:"product w-100 me-5"},[_c('ValidationProvider',{ref:("product-" + index),refInFor:true,staticClass:"w-100",attrs:{"rules":{
                    required: true,
                    in_list: {
                      list: _vm.listProductApi.map(function (item) { return item.name; }).join(' ')
                    }
                  },"name":"product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-center position-relative"},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.productSuggestions,"inputProps":{
                        id: 'autosuggest__input',
                        placeholder: 'Chọn sản phẩm...',
                        class: errors[0]
                          ? 'form-control pe-13 border-danger'
                          : 'form-control pe-13',
                        name: 'product'
                      },"renderSuggestion":_vm.renderSuggestion,"getSuggestionValue":function (s) { return _vm.getSuggestionValue(s, index); },"component-attr-class-autosuggest-results":'autosuggest__results mh--42'},on:{"input":function($event){return _vm.fetchResults(index)},"focus":function($event){return _vm.fetchResults(index)},"selected":function($event){return _vm.triggerInputChange(index)}},model:{value:(product.productName),callback:function ($$v) {_vm.$set(product, "productName", $$v)},expression:"product.productName"}}),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-add-product",modifiers:{"modal-add-product":true}}],staticClass:"cursor-pointer btn-success-less h--5 w--10 rounded-pill flex-center position-absolute end-0 me-1"},[_c('i',{staticClass:"far fa-plus text-light"})])],1),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1 fs-12"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('span',{staticClass:"col"},[_c('div',{staticClass:"form-input"},[_c('ValidationProvider',{attrs:{"name":"contract_value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('CurrencyInput',{staticClass:"form-control bg-light text-align-right fs-11 fs-xl-14 h--8",class:{ 'border-danger': errors[0] },attrs:{"type":"text","name":"contract_value","options":_vm.currencyOption},on:{"change":_vm.onChangeTotal},model:{value:(product.value),callback:function ($$v) {_vm.$set(product, "value", $$v)},expression:"product.value"}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1 fs-12"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('span',{staticClass:"col d-flex"},[_c('div',{staticClass:"form-input"},[_c('ValidationProvider',{attrs:{"name":"contract_weight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('CurrencyInput',{staticClass:"form-control bg-light text-align-right fs-11 fs-xl-14 h--8",class:{ 'border-danger': errors[0] },attrs:{"type":"text","name":"contract_weight","options":_vm.currencyOption},on:{"change":_vm.onChangeTotal},model:{value:(product.weight),callback:function ($$v) {_vm.$set(product, "weight", $$v)},expression:"product.weight"}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1 fs-12"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('span',{staticClass:"col d-flex"},[_c('div',{staticClass:"form-input"},[_c('ValidationProvider',{attrs:{"name":"contract_pack"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('CurrencyInput',{staticClass:"form-control bg-light text-align-right fs-11 fs-xl-14 h--8",class:{ 'border-danger': errors[0] },attrs:{"type":"text","name":"contract_pack","options":_vm.currencyOption},on:{"change":_vm.onChangePack},model:{value:(product.packs),callback:function ($$v) {_vm.$set(product, "packs", $$v)},expression:"product.packs"}}),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1 fs-12"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"btn-danger cursor-pointer h--6 w--13 ms-2 rounded-pill flex-center mt-1",on:{"click":function($event){return _vm.deleteRow(index)}}},[_c('i',{staticClass:"fas fa-trash-alt text-light"})])])])],1)}),_c('div',{staticClass:"cursor-pointer btn-success h--6 w--20 mt-2 ms-3 rounded-pill flex-center",on:{"click":_vm.addNewRow}},[_c('span',{staticClass:"text-light pe-2"},[_vm._v("Add")]),_c('i',{staticClass:"fas fa-plus text-light"})])],2)]),_c('ModalAddCommon',{attrs:{"id":"modal-add-product","modalType":_vm.eModalAddType.PRODUCT,"listName":_vm.listProductApi.map(function (i) { return i.name; })}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header row fwb pb-3 mx-1 border-bottom border-secondary"},[_c('span',{staticClass:"col-4 ps-6"},[_vm._v(" Loại mặt hàng "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('span',{staticClass:"col",attrs:{"title":"Đơn giá hải quan"}},[_vm._v(" Đơn giá HQ ($/kg) ")]),_c('span',{staticClass:"col"},[_vm._v(" Khối lượng (kg) ")]),_c('span',{staticClass:"col"},[_vm._v("Số kiện")])])}]

export { render, staticRenderFns }